<template>
    <div class="page-content w-100" ref="content"
        :class="$store.state.current.viewport">
    </div>
</template>

<script>

import EditableCommon from './EditableCommon.js';

export default {
    name: "EditablePage",
    props: {
        data: {
            type: Object, 
            default: function() {
                return {
                    source_page: null
                };
            } 
        },
        tokens: Object,
        updateField: Function,
        updateFieldIntent: Function,
    },

    data() {
        return {
            titleSuffix: 'Sales Pages: ',
            frame: null
        }
    },

    watch: {
        data: {
            source_page: {
                handler: function(source_page) {   
                    console.log(source_page);
                    console.log('%csource_page: ' + source_page, 'color: red');
                }
            }
        },
    },

	methods: {...EditableCommon.methods, ...{

        edit() {
            let data = this.$store.getters.currentStrategy;
            let modal = this.$root.modal({
                title: 'Update strategy',
                context: data,
                okay: {
                    label: 'UPDATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let formData = component.data;
                        let request = {
                            ID: data.ID,
                            post_title: this.titleSuffix + formData.title,
                            post_excerpt: formData.excerpt,
                            meta: {
                                source_key: formData.source_key,
                                source_page: formData.source_page,
                                source_project: formData.source_project
                            }
                        };

                        this.$store.commit('SET_LOADING_STATE', true);
                        let response = await this.$store.getters.wp.updatePageStrategy(request);

                        if(response.source_page != data.source_page || response.source_key != data.source_key || response.source_project != data.source_project) {
                            setTimeout( this.getPage, 0);
                        }

                        if(response) {
                            data.title = response.title;
                            data.excerpt = response.excerpt;
                            data.source_project = response.source_project;
                            data.source_page = response.source_page;
                            data.source_key = response.source_key;
                        }

                        this.$store.commit('SET_LOADING_STATE', false);
                    }
                }, 

            }, 'ModalContentPageStrategy');

            setTimeout(() => {
                modal.content().data.title = data.title + '';
                modal.content().data.excerpt = data.excerpt + '';
                modal.content().data.source_key = data.source_key + '';
                modal.content().data.source_project = data.source_project + '';
                modal.content().data.source_page = data.source_page + '';
            }, 0);
            // return false;
        },

        design() {
            let title = 'Edit with Appcropolis Builder';
            let message = 'Are you sure you would like to edit with the Appcropolis Builder?';
            let url = this.data.source_edit_url;

            this.$root.notify({
                type: 'confirm',
                title: title,
                message: message, 
                callback(okay) {
                    okay && window.open(url, 'appcropolis-builder');
                }
            });
        },

        wpDownload() {
            this.$root.notify('Download WP code');
        },
        cancel() {
            return false;
        },

        copy() {
            return false;
        },

        reset() {
            return false;
        },

        update() {
            return false;
        }, 


        reload() {
            this.getPage();
        }, 

        resize() {
            if(!this.frame) {
                return;
            }
            let ifrm = this.frame.contentWindow || this.frame.contentDocument;
            let h = ifrm.document.body.offsetHeight;

            this.frame.style.height = h + 'px';
            this.$refs.content.style.height = (h * .66666) + 10 + 'px';
            // console.log('Height: ', h)
            return h;
        },

        // trash() {
        //     if(this.data?.name) {
        //         this.$root.notify({
        //             type: 'confirm',
        //             title: 'Delete page',
        //             message: 'Are you sure you want to delete this page ('+ this.data.title +')?',
        //             callback: async(okay)=>{
        //                 if(okay) {
        //                     this.$store.commit('SET_LOADING_STATE', true);
        //                     await this.$store.dispatch('trashStrategy', this.data.name)
        //                     this.$store.commit('SET_LOADING_STATE', false);
        //                     this.$router.push( {
        //                         path: this.$root.links.MY_STRATEGIES
        //                     } )
        //                 }
        //             }
        //         });
        //     } else {
        //         this.$root.noticy('ERROR: unable to find strategy.');
        //     }
        // }, 


        destroyFrame() {
            this.frame && this.frame.remove();
            this.frame = null;
        },

        createIFrame() {
            this.destroyFrame();
            this.frame = document.createElement('IFRAME');
            this.frame.id = 'page';
            this.frame.className = 'frame';
            this.$refs.content.appendChild(this.frame);

            return this.frame;
        },


        async getPage() {
            this.$store.commit('SET_LOADING_STATE', true);
            let currentStrategy = await this.$store.getters.wp.getStrategy(this.$route.params.strategy);
            let html = await this.$store.getters.wp.getStrategyPageHTML(currentStrategy.ID);

            this.frame = this.createIFrame();

            let ifrm = this.frame.contentWindow || this.frame.contentDocument;
                ifrm.document.open();
                ifrm.document.write(html);
                ifrm.document.close();

            /** attempt resizing multiple times assumming it will take time to load the page content */
            setTimeout(this.resize, 100);
            setTimeout(this.resize, 250);
            setTimeout(this.resize, 500);
            setTimeout(this.resize, 1000);
            setTimeout(this.resize, 2500);
            setTimeout(this.resize, 5000);

            this.$store.commit('SET_LOADING_STATE', false);
        }
    }},

    unmounted() {
        this.destroyFrame();
    },

    async mounted() {
        this.$route.params.kind == 'sales-pages' && await this.getPage();
        window.page = this;
    },
};
</script>

<style lang="css">


.page-content,
.page-content .frame {
    transition: all 0.2s;
}

.page-content.desktop {
    transform: scale(0.66666);
    transform-origin: top left;
    min-height: 400px;
}
.page-content.mobile {
    transform: scale(1);
    transform-origin: top left;
    min-height: 400px;
}


.page-content.desktop .frame {
    width: 1200px;
    height: 1200px;
}
.page-content.mobile .frame {
    width: 425px;
}
</style>
