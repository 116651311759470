<template>
    <div class="pb-4 d-flex flex-row">
        <div class="strategy-title copyable position-relative d-flex flex-row">
            <div @dblclick.prevent="action('details')" class="fs-4">{{ title }}</div>

            <!-- <a href="#" class="bg-white btn-circl btn-copy btn-sm rounded-circle shadow-sm text-center text-primary ms-2"
                @click.prevent="copyTitle()"
                >
                <span class="material-icons-outlined fs-5">
                content_copy
                </span>
            </a> -->
        </div>

        <div class="d-flex flex-fill justify-content-end options">
            <!-- <a href="#"
                class="icon border border-danger btn-cancel icon ms-2 text-danger btn-cancel ms-2"
                @click.prevent="cancel()"
                v-if="current.mode == modes.EDIT">
                <span class="material-icons-outlined" 
                style="margin-top: 5px;">
                close
                </span>
            </a> -->

            <a href="#"
                class="icon btn-circle btn-edit ms-2"
                :style="{
                    'background-color': '#d052d0 !important',
                    'border-color': '#d052d0 !important',
                    'color': '#ffffff !important'
                }"
                @click.prevent="action('ai')">
                <span class="material-icons-outlined mt-1">
                auto_awesome
                </span>
            </a>


            <a href="#"
                class="icon btn-circle btn-edit ms-2"
                @click.prevent="action('ai-history')">
                <span class="material-icons-outlined mt-1">
                history
                </span>
            </a>


            <!-- <a href="#"
                class="icon btn-circle btn-update ms-2"
                @click.prevent="update()"
                v-if="current.mode == modes.EDIT">
                <span class="material-icons">
                    save
                </span>
            </a> -->


            <!-- <a href="#"
                class="icon btn-circle ms-2"
                @click.prevent="action('clipboard')">
                <span class="material-icons">
                    content_copy
                </span>
            </a> -->

            <DropdownAIStrategy ref="options" class="ms-2" :select="action"></DropdownAIStrategy>
        </div>
    </div>
</template>

<script>
import DropdownAIStrategy from './DropdownAIStrategy.vue';


export default {
    name: 'OptionsEditableAI',

    components: {
        DropdownAIStrategy
    },

    props: {
        title: String,
        select: Function
    },

    data() {
        return {
            selection: null,
            contentHTMLBefore: '',
            contentSourceBefore: '',
            hasChanged: false, 
            modes: {
                EDIT: 'edit',
                VIEW: 'view',
            },

            // displays: {
            //     OUTPUT: 'output',
            //     SOURCE: 'source',
            // },

            current: {
                mode: 'view', 
                display: 'output'
            }
        };
    },

    methods: {
        action(name, data) {
            // console.log(name, data);
            (typeof this.select == 'function') && this.select(name, data);
        }
    }
}
</script>

<style>

</style>