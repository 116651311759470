
export default {
    props: {
        updateField: Function,
        source: String,
        tokens: Object
    },

    methods: {

        mode(mode) {
            if(!mode) {
                return this.$refs.target.mode();
            }
            this.$refs.target?.mode(mode);
        },

        display(display) {
            if(!display) {
                return this.$refs.target.display();
            }
            this.$refs.target.display(display);
        },
    
        content() {
            let content = this.$refs.target.content();
            return content;
        },

        copy() {
            this.$refs.target.copy();
        },

        edit() {
            this.$refs.target.edit();
        },

        cancel() {
            this.$refs.target.cancel();
        },

        reset() {
            this.$refs.target.reset();
        },
        
        trash() {
            if(this.data?.name) {
                this.$root.notify({
                    type: 'confirm',
                    title: 'Delete ' + this.data.kind,
                    message: 'Are you sure you want to delete this '+ this.data.kind +' ('+ this.data.title +')?',
                    callback: async(okay)=>{
                        if(okay) {
                            this.$store.commit('SET_LOADING_STATE', true);
                            await this.$store.dispatch('trashStrategy', this.data.name)
                            this.$store.commit('SET_LOADING_STATE', false);
                            this.$router.push( {
                                path: this.$root.links.MY_STRATEGIES
                            } )
                        }
                    }
                });
            } else {
                this.$root.noticy('ERROR: unable to find '+ this.data.kind +'.');
            }
        }, 
    }
}