<template lang="">

    <div class="layout-stack vh-100 w-100 bg-canvas" data-cmp="OpportunityDetails">
        <AppHeader class="layout-stack-header"></AppHeader>
        <!-- subheader -->
        <div class="layout-stack-subheader position-relative bg-white border-bottom border-start d-flex flex-row navbar-wrapper px-3 ps-4 --shadow-sm">
            <div class="align-items-center d-flex">
                <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 px-1 rounded-pill">
                    <span class="material-icons pull-left">
                    chevron_left
                    </span>
                </a>
            </div>

            <div>
                <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                        <li class="breadcrumb-item">
                            <router-link :to="$root.links.DASHBOARD"
                                class="text-decoration-none"
                                >
                                <span>{{$root.labels.DASHBOARD}}</span>
                            </router-link>
                        </li>


                        <li class="breadcrumb-item">
                            <router-link :to="`/edit/opportunities/${strategy?.opportunity_name}/business/overview`"
                                class="text-decoration-none ellipsis d-inline-block title-opportunity"
                                >
                                <span>{{ strategy?.opportunity_title}}</span>
                            </router-link>

                            <a v-if="$store.state.current?.strategy && !strategy?.opportunity_title" 
                                class="btn-unassined text-danger text-decoration-none px-2 rounded-3" 
                                href="#" title="Click to assign this strategy to an opportunity"
                                @click.prevent="details"
                                >
                                UNASSIGNED
                            </a>
                        </li>

                        <li class="breadcrumb-item">
                            <router-link :to="strategyLink"
                                class="text-decoration-none"
                                >
                                <span>Content</span>
                            </router-link>
                        </li>

                        <li class="breadcrumb-item title-strategy ellipsis">
                            <a class="text-reset text-decoration-none" :title="strategy?.title">{{ strategy?.title }}</a>
                        </li>
                    </ol>
                </nav>
            </div>




            <div class="end-0 me-4 position-absolute top-0 end-0" style="margin-top: 10px;">
                <router-link :to="`/library/strategies/ai`"
                    class="btn btn-sm font-size-16 btn-pill btn-primary me-2 px-2.5"
                    >
                    <span class="end-0 material-icons-outlined pull-left me-1">add</span>
                    <span>Add Content</span>
                </router-link>

                <ButtonStrategiesDropdown :opportunityName="strategy?.opportunity_name" class="d-inline"></ButtonStrategiesDropdown>
            </div>
        </div>
        
        
        <!-- Strategy content area (editable) -->
        <div class="layout-stack-content bg-canvas overflow-hidden shadow-inset-md">
            <div class="container-fluid px-0 layout-scrollable-area">   
                <div class="row min-height">
                    <!-- <div class="col-10 col-lg-8 col-xl-7 mx-auto"> -->
                    <div class="col-10 mx-auto">
                        <div class="appc-widget">
                            <StrategyEditor :strategy="strategy"></StrategyEditor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <AppFooter class="mt-5"></AppFooter>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
</template>
<script>

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import StrategyEditor from "./StrategyEditor.vue";
import ButtonStrategiesDropdown from "@/components/ButtonStrategiesDropdown.vue";


export default {
    name: "StrategyDetails",

    components: {
        AppHeader,
        AppFooter,
        StrategyEditor,
        ButtonStrategiesDropdown
    },

    watch: {
        $route(to) {
            if(to.params?.kind) {
                this.fetchCurrentStrategy();
            }
        }
    },

    computed: {
        strategy() {
            let data;
            if(typeof this.$store.state?.current?.strategy != 'undefined') {
                data = this.$store.state.current.strategy;
            } else {
                data = {};
            }
            return data
        }, 

        kindLabel() {
            let value = this.$store.state.current?.strategy?.kind || 'others';
                value = value.split('-').map((part)=>{
                    if(part.length > 1) {
                        part = part[0].toUpperCase() + part.substr(1);
                    } else {
                        part = part.toUpperCase();
                    }
                    return part.trim();
                }).join(' ');
            return value;
        },

        kindLink() {

            let link, kind = this.$route.params?.kind;
            switch (kind) {
                case 'cold-emails':
                    link = this.$root.links.LIBRARY_STRATEGIES_EMAILS;
                    break;
            
                case 'ads':
                    link = this.$root.links.LIBRARY_STRATEGIES_ADS;
                    break;
            
                default:
                    link = this.$root.links.LIBRARY_STRATEGIES_PAGES
                    break;
            }

            return link;
        },

        strategyLink() {
            let path = `/browse/opportunities/${this.$route.params.opportunity}/${this.$route.params.strategy}`;
            return path;
        },

    },

    methods: {
        async fetchCurrentStrategy() {
            let strategyName = this.$route.params?.strategy;
                strategyName && await this.$store.dispatch('fetchStrategy', strategyName);
        }, 

        details() {
            let strategy = this.$store.state.current.strategy;
            this.$root.editStrategyDetails(strategy);
        }
    },

    async mounted() {
        await this.fetchCurrentStrategy();

        let opportunityName = this.$store.state.current.strategy?.opportunity_name;
            opportunityName && await this.$store.dispatch('fetchOpportunity', opportunityName);

        await this.$store.dispatch('getUserCompany');


        // console.log('current.strategy', this.$store.state.current.strategy);
        // console.log('current.opportunity', this.$store.state.current.opportunity);
        // console.log('current.company', this.$store.state.current.company);

    },
}
</script>
<style lang="css" scoped>

.shadow-inset-md {
    box-shadow: inset 0 .125rem .25rem rgba(0, 0, 0, .075);
}

.min-height {
    min-height: 500px;
}

.py-2\.5 {
    padding-top: 0.75rem!important;
    padding-bottom: 0.75rem!important;
}

.layout-stack {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 89px 61px auto;
    grid-template-areas: 
    "header"
    "subheader"
    "content";
}

.layout-stack-header {
    /* border: solid red 2px; */
  grid-area: header;
}
.layout-stack-subheader {
    /* border: solid green 2px;   */
  grid-area: subheader;
}
.layout-stack-content {
    /* border: solid orange 2px; */
  grid-area: content;
}
.layout-scrollable-area {
    height: calc( 100vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
}


.navbar-wrapper {
    padding-top: 0.75rem!important;
    padding-bottom: 0.75rem!important;
} 


.content-wrapper {
    max-width: 990px;
    min-width: 670px;
}


.btn-unassined {
    background-color: #ff000014;
}


.title-strategy {
    max-width: 480px;
}

.title-opportunity {
    max-width: 300px;
}
</style>