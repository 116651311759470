<template>
    <div class="">
        <div class="dropdown d-inline">
            <a class="btn btn-circle p-0 dropdown-toggle no-carret" data-bs-toggle="dropdown">
                <span class="material-icons mx-auto w-100">
                more_horiz
                </span>
            </a>

            <ul class="dropdown-menu shadow border-minimal dropdown-menu-end border-radius-8 px-0 py-2">
                <li><router-link 
                    :to="$root.links.LIBRARY_STRATEGIES_AI"
                    class="dropdown-item px-4 py-1.5 transition-speed-5x hover:bg-light text-truncate">
                    Add Strategy
                    </router-link>
                </li>

                <!-- <li><hr class="bg-light dropdown-divider"></li>

                <li><router-link 
                    :to="$root.links.MY_STRATEGIES"
                    class="dropdown-item px-4 transition-speed-5x hover:bg-light text-truncate">
                    My Strategies
                    </router-link>
                </li> -->
                <div v-show="recentStrategies?.length > 0">
                    <li><hr class="bg-light dropdown-divider"></li>
                    <li><h6 class="dropdown-header">STRATEGIES</h6></li>

                    <li v-for="(strategy, index) in recentStrategies" :key="index">
                        <router-link 
                            :title="strategy.title"
                            class="dropdown-item px-4 py-1.5 transition-speed-5x hover:bg-light text-truncate" 
                            :to="'/edit/strategies/'+ strategy.kind +'/'+ strategy.name" 
                            >
                            <span class="material-icons-outlined text-secondary pull-left me-2">
                            {{ adIcon(strategy.kind) }}
                            </span>
                            {{ strategy.title }}</router-link>
                    </li>

                    <!-- <li v-show="recentStrategies?.length > 0">
                        <span 
                            class="dropdown-item dropdown-item-last lh-lg px-4 pt-3 transition-speed-5x hover:bg-light text-primary">
                            <router-link 
                                :to="$root.links.MY_STRATEGIES" class="btn btn-sm btn-outline-secondary hover:btn-outline-primary px-2 w-100 text-truncate">
                                SEE ALL {{strategies.length}} STRATEGIES
                            </router-link>
                        </span>
                    </li> -->
                </div>

                <div v-show="recentStrategies?.length === 0" class="px-4">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ButtonStrategiesDropdown', 

        props: {
            opportunityName: String, 
            limit: {
                type: Number, 
                default() {
                    return 10;
                }
            }
        },
        
        data() {
            return {
                strategies: []
            };
        }, 

        computed: {

            recentStrategies() {
                let currentOpportunity = this.opportunityName || this.$route.params.opportunity;
                let collection = [];
                this.strategies.forEach((item, i)=>{
                    if(item.opportunity_name == currentOpportunity && i < this.limit) {
                        collection.push(item);
                    }
                });

                return collection;
            }
        }, 


        methods: {
            adIcon(kind) {
                let icon = 'crop_free';
                switch(kind) {
                    case 'ads': 
                        icon = 'ads_click';
                    break;
                    case 'cold-emails': 
                        icon = 'forward_to_inbox';
                    break;
                    case 'sales-pages': 
                        icon = 'request_page';
                    break;
                    case 'ai': 
                        icon = 'psychology';
                    break;
                }

                return icon;
            }
        },

        created() {
        },

        async mounted() {
            let dropdown = this.$el?.querySelector('.dropdown');
            let spinner = this.$el?.querySelector('.spinner-border');
                spinner.classList.remove('d-none');

            dropdown.addEventListener('shown.bs.dropdown', async ()=> {
                this.strategies = await this.$store.dispatch('fetchStrategies');

                setTimeout(() => {
                    spinner.classList.add('d-none');
                }, 500);
            });
            window.dd = this;
        }
    }
</script>

<style lang="css" scoped>

.dropdown-menu {
    width: 280px;
}

.dropdown-menu .dropdown-item-last:hover {
   background-color: transparent !important;
}

.dropdown-item.disabled {
    opacity: 0.5;
}

.hover\:btn-outline-primary:hover {
    color: #ffffff !important;
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
}
</style>