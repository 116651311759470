<template lang="">
    <div ref="target" :class="className">{{fieldValue}}</div>
</template>
<script>
export default {
    name: 'FieldEditable',

    props: {
        classes: {
            type: String, 
            default: ''
        }, 

        fieldValue: String,
        fieldName: String,
        update: Function
    }, 

    data() {
        return {
            current: {
                mode: 'view'
            }, 

            modes: {
                EDIT: 'edit',
                VIEW: 'view'
            }
        }
    }, 

    computed: {
        className() {
            let editing = this.current.mode == this.modes.EDIT? ' edit-mode ' : '';
            return this.classes + editing;
        }
    },

    watch: {
        current: {
            deep: true,

            handler(current) {
                if(current.mode == this.modes.EDIT) {
                    this.target().setAttribute('contenteditable', true);
                } else {
                    this.target().removeAttribute('contenteditable');
                }
            },
        }
    },

    methods: {
        target() {
            return this.$refs.target;
        },

        mode(mode) {
            if(!mode) {
                return this.current.mode;
            }
            this.current.mode = mode;
        },

        edit() {
            this.mode(this.modes.EDIT);
        },

        view() {
            this.mode(this.modes.VIEW);
        }, 

        content() {
            let value = this.target().textContent;
            return value;
        },

        commit() {
            let value = this.target().textContent;
            typeof this.update == 'function' && this.update(this.fieldName, value);
            this.view();
        },

        cancel() {
            this.view();
        },

        reset() {
            this.view();
        },
    },

    mounted() {
        window[this.fieldName] = this;
    },
}
</script>


<style lang="css">
.edit-mode {
    background-color: lemonchiffon;
    outline: solid 1px rgba(0,0,0,.1);
}
</style>