<template>
    <div class="card-wrapper w-100">
        <div class="card border-0 shadow-none">
            <div class="card-header pt-3 border-0 d-flex flex-row bg-transparent">
                <div class="profile rounded-circle me-2 flex-shrink-0 flex-grow-0"></div>
                <div class="">
                    <h3 class="fs-5 fw-normal mb-0 pb-1 pt-1">{{ companyName }}</h3>
                    <span class="text-muted fs-6"
                        >Sponsored - <i class="fa fa-globe"></i
                    ></span>
                </div>
            </div>

            <div class="card-body copyable pb-0 position-relative">
                <ContentEditable ref="target"
                    :source="data.content" 
                    :highlight="true"
                    :tokens="tokens" 
                    :update="update"
                    :updateFieldIntent="updateFieldIntent"
                    :theme="`theme-plain`"
                    class="mb-2"></ContentEditable>

                <figure class="wide mb-0 position-relative">
                    <img :src="data.thumbnail" class="img-fluid w-100"/>
                </figure>



                <a href="#" class="bg-white btn-circl btn-copy btn-sm end-0 position-absolute pull-right rounded-circle shadow-sm text-center text-primary top-0 me-4"
                    @click.prevent=""
                    ><span class="material-icons-outlined fs-5">content_copy</span>
                </a>
            </div>

            <div class="card-footer bg-transparent border-0 mt-auto pt-3 pb-1 text-center">
                <!-- call to action -->
                <div class="call-to-action d-flex flex-row justify-content-between pb-2 pt-3 px-3">
                    <div class="text-start">
                        <small class="text-muted d-block">{{domainName}}</small>
                        <div class="position-relative copyable d-flex flex-row">
                            <FieldEditable ref='title'
                                :fieldName="`title`"
                                :fieldValue="data.title"
                                :update="updateField"
                                :classes="`strategy-title mb-1`"
                            ></FieldEditable>

                            <a href="#" class="bg-white btn-circl btn-copy btn-sm ms-2 --end-0 --position-absolute --pull-right rounded-circle shadow-sm text-center text-primary top-0 me-1"
                                @click.prevent=""
                                ><span class="material-icons-outlined fs-5">content_copy</span>
                            </a>
                        </div>

                        <div class="position-relative copyable d-flex flex-row">
                            <FieldEditable ref='excerpt'
                                :fieldName="`excerpt`"
                                :fieldValue="data.excerpt"
                                :update="updateField"
                                :classes="`strategy-excerpt text-muted`"
                            ></FieldEditable>

                            <a href="#" class="bg-white btn-circl btn-copy btn-sm ms-2 --end-0 --position-absolute --pull-right rounded-circle shadow-sm text-center text-primary top-0 me-1"
                                @click.prevent=""
                                ><span class="material-icons-outlined fs-5">content_copy</span>
                            </a>
                        </div>
                    </div>

                    <div class="flex-grow-0 flex-shrink-0">
                        <a @click.prevent="updateFieldIntent('offer.call_to_action', tokens['offer.call_to_action'])" class="btn btn-cta px-3 --pointer-events-none">
                            {{ tokens['offer.call_to_action'] || 'Learn more'}}
                        </a>
                    </div>
                </div>


                <!-- likes and comments -->
                <div class="pt-3 d-flex flex-row justify-content-between pb-2 text-muted">
                    <div>
                        <i class="reaction rounded-circle text-white d-inline-block position-relative bg-primary">
                            <span class="material-icons">
                            thumb_up
                            </span>
                        </i>
                        <i class="reaction rounded-circle text-white d-inline-block position-relative bg-danger shift-left">
                            <span class="material-icons">
                            favorite
                            </span>
                        </i>
                        <span class="lh-lg ms-2 pull-right">{{ rand() }}</span>
                    </div>
                    <div>
                        <span class="lh-lg me-2">{{ rand() }} Comments</span> <span>{{ rand() }} Shares</span>
                    </div>
                </div>

                            
                <!-- reaction buttons -->
                <ul class="d-flex flex-row justify-content-evenly list-inline list-unstyled mx-auto px-5 pt-3 border-top">
                    <li class="list-inline-item fs-5 text-muted position-relative lh-sm"><span>
                        <span class="material-icons-outlined pull-left me-2">
                        thumb_up
                        </span>
                        Likes</span></li>

                    <li class="list-inline-item fs-5 text-muted position-relative lh-sm"><span>
                        <span class="material-icons-outlined pull-left me-2">
                        chat_bubble_outline
                        </span> 
                        Comments</span></li>

                    <li class="list-inline-item fs-5 text-muted position-relative lh-sm"><span>
                        <span class="material-icons-outlined pull-left me-2">
                        reply
                        </span> Shares</span></li>
                </ul>
                <a href="#"></a>
            </div>
        </div>
    </div>
</template>

<script>

import ContentEditable from './ContentEditable.vue';
import FieldEditable from './FieldEditable.vue';
import EditableCommon from './EditableCommon.js';

export default {
    name: "EditableAd",
    
    props: {
        data: Object,
        tokens: Object,
        updateField: Function,
        updateFieldIntent: Function,
    },

    components: {
        ContentEditable,
        FieldEditable
    },

    computed: {
        companyName() {
            let name = this.$store.getters.company?.content?.company?.fields?.name;
            return name || '...';
        }, 

        domainName() {
            let name = this.$store.getters.company?.content?.company?.fields?.website;
            if(name && name.length > 0) {
                name = name.replace(/(http|https):\/\//, '').toUpperCase();
            }
            return name || '...';
        }, 


    },

	methods: {...EditableCommon.methods, ...{
        rand() {
            let val = Math.ceil( Math.random() * 250 );
            return val;
        },

        edit() {
            this.$refs.target.edit();
            this.$refs.title.edit();
            this.$refs.excerpt.edit();
        },

        cancel() {
            this.$refs.target.cancel();
            this.$refs.title.cancel();
            this.$refs.excerpt.cancel();
        },

        reset() {
            this.$refs.target.reset();
            this.$refs.title.reset();
            this.$refs.excerpt.reset();
        },

        update() {
            let title = this.$refs.title.content();
            let excerpt = this.$refs.excerpt.content();
            let content = this.$refs.target.content();

            this.$store.state.current.strategy.content = content;
            this.$store.state.current.strategy.title = title;
            this.$store.state.current.strategy.excerpt = excerpt;

            this.$store.dispatch('updateCurrentStrategy');
        },

    }},

    async mounted() {
        await this.$store.dispatch('getUserCompany');
    },
};
</script>

<style lang="css" scoped>


figure {
    background-color: #92b6da;
    margin: 0 -1rem;
}

figure img {
    opacity: 0.3;
}


.card-wrapper .content-editable {
    font-size: 20px;
}



.card {
    border-radius: 8px;
}



.card .card-header .profile {
    width: 55px;
    height: 55px;
    background-color: var(--bg-secondary-darker);
}

.card-footer .call-to-action {
    background-color: #f0f2f5;
    margin-top: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.card-footer .strategy-title {
    font-size: 18px;
    font-weight: 500;
}

.card-footer .btn-cta {
    background-color: #e4e6eb;
    font-weight: 500;
}
.card-footer .btn-cta:hover {
    background-color: #dcdfe4;
}





.card .reaction {
   width: 28px;
   height: 28px; 
   padding: 0;
   border: solid 2px white;
    z-index: 1;    
}
.card .reaction span {
    zoom: 0.7;
    float: left;
    margin: 5px;
    line-height: 25px;
}
.card .reaction:not(.shift-left) {
    z-index: 10;
}
.card .reaction.shift-left {
    margin-left: -7px;
    z-index: 1;
}


.copyable .btn-copy {
    opacity: 0;
    transition: all 0.25s;
    border: solid 1px rgba(0,0,0,.1);
    width: 36px;
    height: 36px;
    padding-top: 6px;
    margin-top: -6px;
}
.copyable:hover .btn-copy {
    opacity: 1;
}

</style>
