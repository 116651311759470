<template lang="">
    <div>
        <ContentEditable ref="target"
            :source="data?.content" 
            :tokens="tokens" 
            :update="update"
            :updateField="updateField"
            :updateFieldIntent="updateFieldIntent"
            >
        </ContentEditable>
    </div>
</template>
<script>

import ContentEditable from './ContentEditable.vue';
import EditableCommon from './EditableCommon.js';


export default {
    name: 'EditableEmail',

    components: {
        ContentEditable
    },
    
    props: {
        updateField: Function,
        updateFieldIntent: Function,
        data: Object,
        tokens: Object
    },

    methods: {...EditableCommon.methods, ...{
        async update() {
            let content = this.$refs.target.content();
            this.$store.state.current.strategy.content = content;
            await this.$store.dispatch('updateCurrentStrategy');
        }
    }}, 
}
</script>
<style lang="">
    
</style>