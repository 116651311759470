<template lang="">
    <div>
        <div id="ai-editor" ref="editor">

        </div>
    </div>
</template>
<script>

// import ContentEditable from './ContentEditable.vue';
import EditableCommon from './EditableCommon.js';
import {copyToClipboard} from '@/plugins/helper.js';

var quill;

export default {
    name: 'EditableAI',

    components: {
        // ContentEditable
    },
    
    props: {
        updateField: Function,
        updateFieldIntent: Function,
        data: Object,
        tokens: Object
    },

    data() {
        return {
            // quill: null
        }
    },

    methods: {...EditableCommon.methods, ...{
        // async update() {
        //     let content = this.$refs.target.content();
        //     this.$store.state.current.strategy.content = content;
        //     await this.$store.dispatch('updateCurrentStrategy');
        // }, 
        async update() {
            let content = quill.root.innerHTML;
            this.$store.state.current.strategy.content = content;
            await this.$store.dispatch('updateCurrentStrategy');
        }, 


        copy() {
            let editor = this.$refs.editor;
            copyToClipboard(editor, (selection)=>{
                let message = 'The content has been copied to your clipboard. You can now open your email/text editor and paste it (Cmd+v).'
                this.$root.notify({
                    title: 'Yay!', 
                    type: 'alert', 
                    message: message, 
                    callback: ()=> {
                        selection.removeAllRanges();
                        // tokens.forEach((token)=>{
                        //     let $token = window.$(token);
                        //         $token.addClass('token');
                        //         $token.removeClass('font-weight-bold');
                        // });
                    }
                });
            });
        },

        setContent(html) {
            quill.pasteHTML(html);
        },
        
        getContent() {
            let content = quill.root.innerHTML;
            return content;
        },
        

        quillInit() {
            var self = this;
            // INITIALIZE QUILL 
            var toolbarOptions = [
                [{ 'header': [1, 2, 3, 4, 5, false] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote', 'code-block'],
                /*
                [{ 'header': 1 }, { 'header': 2 }], // custom button values
                */
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
                /*
                [{ 'direction': 'rtl' }], // text direction
                [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
                */

                [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
                /*
                [{ 'font': [] }],
                */
                [{ 'align': [] }],

                ['clean'] // remove formatting button
            ];

            quill = new window.Quill('#ai-editor', {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: 'snow'
            });


            quill.pasteHTML(this.data.content);

            quill.on('text-change', function(delta, oldDelta, source)  {
                delta, oldDelta;
                if (source == 'api') {
                    // console.log("An API call triggered this change.");
                } else if (source == 'user') {
                    // console.log("A user action triggered this change.");
                    self.update();
                }

            });

            window.quill = this.quill;

            return this.quill;
        }
    }}, 

    mounted() {
        setTimeout(this.quillInit, 0);
    }
}
</script>
<style lang="css">
    
/* quill */

.ql-container {
    font-size: 16px;
}

.ql-editor {
    /* padding-left: 5%;
    padding-right: 5%; */
    padding-top: 28px;
}

.ql-toolbar.ql-snow {
    border-left: none;
    border-right: none;
    border-top: none;
}

.ql-container.ql-snow {
    border-left: none;
    border-right: none;
    border-bottom: none;
}


.ql-editor h1, 
.ql-editor h2, 
.ql-editor h3, 
.ql-editor h4, 
.ql-editor h5, 
.ql-editor ul, 
.ql-editor ol, 
.ql-editor pre, 
.ql-editor p {
    margin-bottom: 0.8rem;
}


</style>