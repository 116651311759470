<template>
    <div class="dropdown-ai-strategy">
        <div class="dropdown d-inline">
            <button class="btn-circle text-center dropdown-toggle no-carret border border-color-light" 
                type="button" 
                data-bs-toggle="dropdown">
                <i :style="{ fontSize: '23px', margin: 0}"
                class="material-icons keyboard_arrow_left me-1 pull-left">more_horiz</i>
            </button>


            <ul class="dropdown-menu shadow border-minimal border-radius-8">
                <li><a
                    href="#"
                    @click.prevent="choose('ai')"
                    class="dropdown-item">
                    AI Suggestions
                    </a>
                </li>


                <li><a
                    href="#"
                    @click.prevent="choose('ai-history')"
                    class="dropdown-item">
                    History
                    </a>
                </li>

                <li><hr class="dropdown-divider"></li>

                <li><a
                    href="#"
                    @click.prevent="choose('details')"
                    class="dropdown-item">
                    Details
                    </a>
                </li>

                <!-- <li><a
                    href="#"
                    @click.prevent="choose('update')"
                    :class="[mode == 'view'? 'disabled' : '']"
                    class="dropdown-item">
                    Save
                    </a>
                </li> -->

                <li><a
                    href="#"
                    @click.prevent="choose('clipboard')"
                    class="dropdown-item">
                    Copy
                    </a>
                </li>


                <li><a
                    href="#"
                    @click.prevent="choose('download')"
                    class="dropdown-item">
                    Download
                    </a>
                </li>


                <!-- <li><hr class="dropdown-divider"></li>
                <li><a
                    href="#"
                    @click.prevent="choose('source')"
                    class="dropdown-item">
                    Source
                    </a>
                </li> -->


                <!-- <div v-if="user?.is_admin">
                    <li><hr class="dropdown-divider"></li>
                    <li><h6 class="dropdown-header font-size-11 text-muted">ADMIN</h6></li>
                    <li><a
                        href="#"
                        @click.prevent="choose('template')"
                        class="dropdown-item disabled">
                        Save as Template
                        </a>
                    </li>
                </div> -->



                <li><hr class="dropdown-divider"></li>


                <li><a
                    href="#"
                    @click.prevent="choose('trash')"
                    class="dropdown-item text-danger">
                    <span class="material-icons-outlined pull-left me-1"
                        style="margin-top: -1px;">
                    delete
                    </span>

                    Delete
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DropdownAIStrategy',
        props: {
            // mode: String,
            select: Function
        },

        data() {
            return {
                strategies: []
            };
        }, 
        

        computed: {
            // user() {
            //     return this.$store.getters.user;
            // }
        }, 


        methods: {
            choose(choice) {
                typeof this.select == 'function' && this.select(choice);
            }
        },

        mounted() {
            let $ = window.$;
            /**
             * @see: https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
             */
            $('.dropdown-ai-strategy .dropdown-menu a.dropdown-toggle').on('click', function() {
                return false;
            });


        }
    }
</script>

<style lang="css" scoped>
.dropdown-item.disabled {
    opacity: 0.5;
}
</style>