<template>
    <div
        id="cmp-strategy-editor"
        ref="strategyEditor"
        v-if="show"
        class="strategy-editor strategy-content my-5 mx-auto bg-white shadow pt-4 pb-0 px-4.5 border-radius-8"
        :class="[`kind-${strategy?.kind} ${$store.state.current.viewport}`]"
        >

        <!-- Page Strategy Options -->
        <div class="pb-4 d-flex flex-row justify-content-center position-relative"
            v-if="showControlsForPages"
            >
            <a class="btn-circle d-flex justify-content-center mx-1 text-decoration-none" href="#"
                :class="[$store.state.current.viewport == 'desktop'? 'disabled pointer-events-none' : '']"
                @click.prevent="viewport('desktop')"
                >
                <span class="material-icons-outlined" style="line-height: 34px">
                desktop_windows
                </span>
            </a>
            <a class="btn-circle d-flex justify-content-center mx-1 text-decoration-none" href="#"
                :class="[$store.state.current.viewport == 'mobile'? 'disabled pointer-events-none' : '']"
                @click.prevent="viewport('mobile')"
                >
                <span class="material-icons-outlined" style="line-height: 34px">
                phone_iphone
                </span>
            </a>
            <DropdownEditPageStrategy ref="options" class="ms-2 position-absolute pull-right top-0 end-0" :mode="current.mode" :select="dropdownSelect"></DropdownEditPageStrategy>
        </div>






        <!-- Text-based (Ads, Emails) Strategy Options -->
        <div class="pb-4 d-flex flex-row"
            v-if="showControlsForTextBased"
            >
            <div class="strategy-title copyable position-relative d-flex flex-row">
                <div @dblclick.prevent="dropdownSelect('details')" class="fs-4">{{ title }}</div>

                <!-- <a href="#" class="bg-white btn-circl btn-copy btn-sm rounded-circle shadow-sm text-center text-primary ms-2"
                    @click.prevent="copyTitle()"
                    >
                    <span class="material-icons-outlined fs-5">
                    content_copy
                    </span>
                </a> -->
            </div>

            <div class="d-flex flex-fill justify-content-end options">
                <a href="#"
                    class="icon border border-danger btn-cancel icon ms-2 text-danger btn-cancel ms-2"
                    @click.prevent="cancel()"
                    v-if="current.mode == modes.EDIT">
                    <span class="material-icons-outlined" 
                    style="margin-top: 5px;">
                    close
                    </span>
                </a>

                <a href="#"
                    class="icon btn-circle btn-edit ms-2"
                    @click.prevent="edit()"
                    v-if="current.mode == modes.VIEW">
                    <span class="material-icons">
                        edit
                    </span>
                </a>


                <a href="#"
                    class="icon btn-circle btn-update ms-2"
                    @click.prevent="update()"
                    v-if="current.mode == modes.EDIT">
                    <span class="material-icons">
                        save
                    </span>
                </a>


                <a href="#"
                    class="icon btn-circle ms-2"
                    :class="[this.current.display == this.displays.SOURCE? 'disabled pointer-events-none' :  '']"
                    @click.prevent="copy()">
                    <span class="material-icons">
                        content_copy
                    </span>
                </a>

                <DropdownEditTextBasedStrategy ref="options" class="ms-2" :mode="current.mode" :select="dropdownSelect"></DropdownEditTextBasedStrategy>
            </div>
        </div>






        <!-- AI Strategy Options -->
        <OptionsEditableAI v-if="showControlsForAI" :title="title" :select="dropdownSelect" ></OptionsEditableAI>
        

        <!-- Others Strategy Options -->
        <OptionsEditableAI v-if="showControlsForOthers" :title="title" :select="dropdownSelect" ></OptionsEditableAI>



        <div v-if="strategy?.kind == 'ai'"
            class="editor-wrapper position-relative pb-4">
            <EditableAI ref="ai"
                :data="strategy" 
                :tokens="tokens" 
                :updateField="updateField"
                :updateFieldIntent="updateFieldIntent"
                >
            </EditableAI>
        </div>


        <div v-if="strategy?.kind == 'others'"
            class="editor-wrapper position-relative pb-4">
            <EditableAI ref="others"
                :data="strategy" 
                :tokens="tokens" 
                :updateField="updateField"
                :updateFieldIntent="updateFieldIntent"
                >
            </EditableAI>
        </div>


        <div v-if="strategy?.kind == 'cold-emails'"
            class="editor-wrapper position-relative pb-4">
            <EditableEmail ref="cold_email"
                :data="strategy" 
                :tokens="tokens" 
                :updateField="updateField"
                :updateFieldIntent="updateFieldIntent"
                >
            </EditableEmail>
        </div>


        <div v-if="strategy?.kind == 'ads'"
            class="editor-wrapper card-facebook-ad-large position-relative border-top">
            <EditableAd ref="ad" 
                :updateField="updateField"
                :updateFieldIntent="updateFieldIntent"
                :tokens="tokens" 
                :data="strategy" data-select="select"></EditableAd>
        </div>


        <div v-if="strategy?.kind == 'sales-pages'"
            class="editor-wrapper editor-page position-relative border-top">
            <EditablePage ref="page" 
                :updateField="updateField"
                :updateFieldIntent="updateFieldIntent"
                :tokens="tokens" 
                :data="strategy" data-select="select"></EditablePage>
        </div>
    </div>


</template>

<script>

import EditableAI from './EditableAI.vue';
import EditableEmail from './EditableEmail.vue';
import EditableAd from './EditableAd.vue';
import EditablePage from './EditablePage.vue';
import DropdownEditTextBasedStrategy from './DropdownEditTextBasedStrategy.vue';
import DropdownEditPageStrategy from './DropdownEditPageStrategy.vue';
import OptionsEditableAI from './OptionsEditableAI.vue';

// debounce

export default {
    name: "StrategyEditor",

    props: {
        implementation: Object,
        strategy: Object
    },

    components: {
        DropdownEditTextBasedStrategy,
        DropdownEditPageStrategy,
        EditableAd,
        EditableEmail,
        EditablePage,
        EditableAI, 

        OptionsEditableAI
    },

    data() {
        return {
            selection: null,
            contentHTMLBefore: '',
            contentSourceBefore: '',
            hasChanged: false, 
            modes: {
                EDIT: 'edit',
                VIEW: 'view',
            },

            displays: {
                OUTPUT: 'output',
                SOURCE: 'source',
            },

            current: {
                mode: 'view', 
                display: 'output'
            }
        };
    },

    computed: {
        show() {
            return (this.strategy && this.strategy?.name == this.$route.params?.strategy);
        },


        title() {
            return this.strategy?.title;
        }, 


        tokens() {
            let tokens = this.$store.getters.tokens;
            return tokens;
        }, 

        source() {
            let content = this.strategy?.content;
            return content;
        },

        showControlsForTextBased() {
            let show = false;
            let kind = this.strategy?.kind;
            if(kind == 'ads' || kind == 'cold-emails') {
                show = true;
            }
            return show;
        },

        showControlsForPages() {
            let show = false;
            let kind = this.strategy?.kind;
            if(kind == 'sales-pages') {
                show = true;
            }
            return show;
        },

        showControlsForAI() {
            let show = false;
            let kind = this.strategy?.kind;
            if(kind == 'ai') {
                show = true;
            }
            return show;
        },

        showControlsForOthers() {
            let show = false;
            let kind = this.strategy?.kind;
            if(kind == 'others') {
                show = true;
            }
            return show;
        }
    },

    methods: {

        viewport(size) {
            // console.log(size);
            this.$store.state.current.viewport = size;
            setTimeout(()=>{
                let factor = size == 'desktop'? 0.6666 : 1;
                let contentHeight = this.$refs.page.resize() * factor;
                // console.log(contentHeight);
                // console.log(this.$refs.page);
                this.$refs.page.$el.style.height = (contentHeight + 10) +'px';
            }, 500);

        },

        /**
         * @param {string} mode Valid values are "view", "edit"
         */
        mode(mode) {
            if(!mode) {
                return this.current?.mode;
            }

            switch (mode) {
                case 'view':
                        this.display(this.displays.OUTPUT);
                    break;

                case 'edit':
                    break;
            
                default:
                    break;
            }

            this.target().mode(mode);
            this.current.mode = mode;
        },

        display(display) {
            if(!display) {
                return this.current?.display;
            }

            this.target().display(display);
            this.current.display = display;
        },
    

        target() {
            if(this.strategy && this.strategy.kind == 'ads') {
                return this.$refs.ad;
            }
            if(this.strategy && this.strategy.kind == 'cold-emails') {
                return this.$refs.cold_email;
            }
            if(this.strategy && this.strategy.kind == 'sales-pages') {
                return this.$refs.page;
            }
            if(this.strategy && this.strategy.kind == 'ai') {
                return this.$refs.ai;
            }
            if(this.strategy && this.strategy.kind == 'others') {
                return this.$refs.others;
            }
        },

        copy() {
            this.target().copy();
        }, 


        copyTitle() {
            console.log(this.title)
        }, 

        edit() {
            this.target().edit();
            this.mode(this.modes.EDIT);
        },

        details() {
            this.$root.editStrategyDetails(this.strategy);
        },

        design() {
            this.target().design();
        },


        wpDownload() {
            this.target().wpDownload();
        },


        reload() {
            this.target().reload();
            // this.mode(this.modes.VIEW);
        },

        cancel() {
            this.target().cancel();
            this.mode(this.modes.VIEW);
        },

        reset() {
            this.target().reset();
            this.mode(this.modes.VIEW);
        },


        trash() {
            this.target().trash();
        },

        download() {
            let scope = this;
            let content = this.target().content();

            this.$root.notify({
                type: 'prompt',
                title: 'Download',
                message: 'What would you like to name this file?',
                value: this.strategy?.title + '.txt',
                callback(okay, value) {
                    if(okay) {
                        scope.$root.download({
                            content: content,
                            fileName: value
                        });
                    }
                }
            })
        },


        /**
         * @param {string} field This is a token (e.g. product.type )
         * @param {string} value
         */
        updateFieldIntent(field, value) {
            let scope = this;
            var modal = this.$root.modal({
                title: 'Edit Field',
                okay: {
                    label: 'UPDATE', 
                    callback(content) {
                        scope.updateFormField(field, content.data.value);
                    }
                }
            }, 'ModalContentField');

            /* modal needs to be fully mounted before calling methods from its content */
            setTimeout(() => {   
                modal.content().setup({
                    token: '' + field, 
                    value: '' + value
                });
            }, 0);
            
            return modal;
        },




        /**
         * @param {string} field Valid values 'title', 'excerpt'
         * @param {string} value
         */
        updateField(field, value) {
            if( field == 'title' || field == 'excerpt' ) {
                this.$store.state.current.strategy[field] = value;
                this.$store.dispatch('updateCurrentStrategy');
            }
        },

        /**
         * 
         * @param {string} field This is a token (e.g. product.type )
         * @param {string} value
         * 
         */
        updateFormField(field, value) {
            let formField = field.split('.').map( (part) => { return part.trim() } );
            let request = {
                form: formField[0],
                field: formField[1],
                value: value, 
                opportunity_id: parseInt( this.strategy.opportunity_id )
            };

            if(formField[0] == 'company') {
                this.$store.dispatch( 'updateUserCompanyFormField', request );

            } else if(formField[0] == 'user') {
                console.error('ERROR: user form cannot be edited.');
                
            } else {
                this.$store.dispatch( 'updateOpportunityFormField', request );
            }
        },


        update() {
            this.target().update()
            this.reset();
        },

        dropdownSelect(choice, data) {
            var scope = this;
            let kind = this.$root.$route.params.kind; // e.g. ai, others, etc.
            data
            let display;
            switch (choice) {
                case 'edit':
                    this.edit();
                    break;

                case 'details':
                    this.details();
                    break;

                case 'design':
                    this.design();
                    break;

                case 'update':
                    this.update();
                    break;

                case 'insert':
                    this.insertTokenIntent();
                    break;

                case 'source':
                    this.current.mode == 'view' && this.edit();
                    display = this.current.display == this.displays.OUTPUT? this.displays.SOURCE : this.displays.OUTPUT;
                    this.display( display );
                    break;

                case 'clipboard':
                    this.copy();
                    break;

                case 'remove':
                    this.remove();
                    break;

                case 'reload':
                    this.reload();
                    break;

                case 'download':
                    this.download();
                    break;

                case 'wpDownload':
                    this.wpDownload();
                    break;

                case 'ai':

                    (async function($root) {
                        let usecase = await $root.AIChooseStrategy();
                        let currentOpportunity = $root.$store.getters.currentOpportunity;
                        let opportunity = {
                            opportunity_id: currentOpportunity.ID,
                            opportunity_name: currentOpportunity.name,
                            opportunity_title: currentOpportunity.title
                        };

                        setTimeout( async () => {
                            let selection = await $root.AIGenerateStrategy(usecase, opportunity);
                            let text = selection?.text;
                            let currentText = scope.$refs[kind].getContent();
                            scope.$refs[kind].setContent(currentText +  text);

                            // TODO: save AI generated copy. Currently it is not saved because the 
                        }, 500);

                    })(this.$root);




                    // this.$root.AIChooseStrategy( function(selection, usecase) {
                    //     selection, usecase;
                    //     let currentText = scope.$refs['ai'].getContent();
                    //     let text = selection?.text;
                    //     scope.$refs['ai'].setContent(currentText +  text);
                    //     scope.$refs['ai'].update();
                    // });
                    break;

                case 'ai-history':
                    this.$root.AIShowHistory( function(selection) {
                        console.log('AIShowHistory::callback', selection);
                        let currentText = scope.$refs[kind].getContent();
                        let text = selection?.text;
                        scope.$refs[kind].setContent(currentText +  text);
                        scope.$refs[kind].update();
                    });
                    break;

                    

                case 'trash':
                    this.trash();
                    break;

                default:
                    console.log(choice, data);
                    break;
            }
        },

    },
    mounted() {
    },
};
</script>

<style lang="css">
#cmp-strategy-editor.kind-ai,
#cmp-strategy-editor.kind-others,
#cmp-strategy-editor.kind-cold-emails { 
    max-width: 800px;
}


#cmp-strategy-editor.kind-sales-pages {
    transition: all 0.2s;
    overflow: hidden;
}



#cmp-strategy-editor.kind-ads { 
    max-width: 680px;
}

#cmp-strategy-editor.kind-sales-pages.desktop { 
    width: 800px;
}
#cmp-strategy-editor.kind-sales-pages.mobile { 
    width: 425px;
}

#cmp-strategy-editor .editor-page, 
#cmp-strategy-editor .card-facebook-ad-large { 
    margin-left: -36px!important;
    margin-right: -36px!important;
}

/* #cmp-strategy-editor .btn-update {
    display: none;
} */
/* #cmp-strategy-editor.mode-edit .btn-update {
    display: inline-block;
} */
#cmp-strategy-editor.mode-edit.changed .btn-update {
    color: red;
}

#cmp-strategy-editor.mode-edit .btn-edit {
    display: none;
}


#cmp-strategy-editor .btn-cancel {
    width: 36px;
    height: 36px;
    display: inline-block;
    color: var(--text-danger);
    background-color: var(--bg-white);
    border: solid 1px var(--text-danger);
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
}

#cmp-strategy-editor .copyable .btn-copy {
    opacity: 0;
    transition: all 0.25s;
    border: solid 1px rgba(0,0,0,.1);
    width: 36px;
    height: 36px;
    padding-top: 6px;
}
#cmp-strategy-editor .copyable:hover .btn-copy {
    opacity: 1;
    /** NOTE: the "copy title" to clipboard button is hidden since the functionality is yout ready */
    display: none;
}

</style>
